<template>
  <v-card tile :class="response.mistakes.length ? 'ma-0 pa-0 black--text' : 'ma-0 pa-0 green--text'">
    <v-card-actions class="ma-0 pa-0" v-if="!loading">
      <v-chip label :class="response.mistakes.length ? 'ml-1 red darken-4 white--text' : 'ml-1 green darken-4 white--text'">{{ selectedRow.sn }}</v-chip>
      <v-card-text class="ma-0 pa-2 textwitherror" v-html="response.highlight"  />
    </v-card-actions>
    <v-progress-linear
      v-else
      indeterminate
      color="green darken-2"
    ></v-progress-linear>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import log from "@/log";
import bus from "@/bus";
import axios from "axios";
export default {
  name: "spell-check",
  data() {
    return {
      response : {
        corpus: '',
        mistakes: []
      },
        loading: false
    };
  },
  computed: {
    ...mapGetters({
      selectedRow: "app/home/selectedRow",
    }),
  },
  watch: {
    selectedRow(val){
     this.handleRowChange()
    }
  },
  methods:{
    handleRowChange() {
      if(this.loading || !this.selectedRow.text || this.selectedRow.text.length < 2){
        return;
      }
      this.loading = true
      axios.post('https://mkblite.khare.co.in/spellcheck', {
        corpus: this.selectedRow.text
      }).then(res=>{
        this.response = res.data;
      }).finally(()=>{
        this.loading = false;
      })
    }
  }
}
</script>

<style>
.textwitherror span{
  text-decoration-line: underline;
  text-decoration-style: double;
  color:red,

}
</style>