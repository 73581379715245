<template>
  <v-row no-gutters v-if="!$store.getters['isLocked']">
    <version-expired />
    <data-editor />
    <data-render v-if="CanRender" />
    <toolbar />
  </v-row>
  <v-layout
    v-else
    class="d-flex justify-center align-center flex-column pa-10"
    fill-height
  >
    <!-- <v-progress-linear indeterminate /> -->
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import bus from "@/bus";
import log from "@/log";
import Toolbar from "@/components/Toolbar";
import DataEditor from "./DataEditor/DataEditor";
import DataRender from "./DataRender/DataRender";
import CanRender from "./CanRender";
import AuthCheck from "@/components/AuthCheck";
import VersionExpired from "@/components/VersionExpired";

export default {
  name: "Home",
  components: {
    Toolbar,
    DataEditor,
    DataRender,
    VersionExpired,
  },

  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      authData: "app/auth/authData",
    }),
    CanRender: CanRender,
  },
  watch: {},
  methods: {
    handleAuthCheck() {
      AuthCheck({
        error: (err) => {
          if (err.status && [403, 401].includes(err.status)) {
            // store.dispatch("setAlert", {
            //   message:
            //     "Due to security reasons, You have been logged out. Please login again.",
            //   type: "orange white--text",
            // });
            this.$router.replace({ name: "logout" });
          }
        },
      });
      // is auth check interval
      this.AuthCheckInterval = setInterval(() => {
        AuthCheck({
          success: (user) => {
            this.$store.dispatch("setLocked", !user.verified);
          },
          error: (err) => {
            this.$store.dispatch("setLocked", true);
            //this.$router.replace({ name: "logout" });
          },
        });
      }, 10000);
    },
  },
  mounted() {
    this.$store.dispatch("setLocked", false);
    // this.handleAuthCheck();
  },
  destroy() {
    if(this.AuthCheckInterval){
      clearInterval(this.AuthCheckInterval);
    }
  },
};
</script>
<style></style>
