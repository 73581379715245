<template>
  <v-dialog
    :value="!$store.getters['isSupportedVersion']"
    fullscreen
    transition="dialog-fade-transition"
  >
    <v-row
      class="fill-height justify-center align-center d-flex"
      style="
        background-color: white;
        margin: 0px;
        padding: 0px;
        overflow: hidden;
      "
    >
      <v-col v-if="isFetched" cols="6" class="">
        <v-card tile>
          <v-card-title class="orange white--text text-center justify-center">
            THIS VERSION HAS EXPIRED
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="text-center justify-center align-center">
            <div class="pb-10">
              To provide seamless user experience, We update the app regularly,
              sometimes changes we make may brake the app or user experiance.
              this is one of those times.
            </div>
            <v-btn
              class="my-10"
              tile
              large
              color="primary"
              @click="handleClick"
            >
              Click here to download the update
            </v-btn>
          </v-card-text>
          <div class="orange white-text" style="height: 4px"></div>
        </v-card>
      </v-col>
      <v-col v-else cols="6" class="">
        <v-card-title class="py-12 black--text text-center justify-center">
          Loading, Please wait...
        </v-card-title>
        <v-progress-linear
          color="deep-purple accent-4"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isFetched: false,
      loading: null,
    };
  },
  computed: {
    ...mapGetters({
      authData: "app/auth/authData",
      isSupportedVersion: "isSupportedVersion",
      supportedVersion: "supportedVersion",
    }),
  },
  mounted() {
    this.loading = this.$store.getters["isSupportedVersion"];
    setTimeout(() => {
      this.isFetched = this.$store.getters["isSupportedVersionFeatched"];
    }, 4000);
  },
  watch: {
    supportedVersion() {
      this.loading = this.$store.getters["isSupportedVersion"];
      setTimeout(() => {
        this.isFetched = this.$store.getters["isSupportedVersionFeatched"];
      }, 3000);
    },
  },
  methods: {
    handleClick() {
      window.open(
        "https://mkblite.khare.co.in/download",
        "_blank",
        "top=100,left=300,frame=true,nodeIntegration=no"
      );
    },
  },
};
</script>

<style>
</style>