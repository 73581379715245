export const maxLength = (state) => {
  return state.maxLength;
};

export const maxEmptyRows = (state) => {
  return state.maxEmptyRows;
};

export const activeColumn = (state) => {
  return state.activeColumn;
};

export const serialColumn = (state) => {
  return state.serialColumn;
};

export const lengthColumn = (state) => {
  return state.lengthColumn;
};

export const linesColumn = (state) => {
  return state.linesColumn;
};

export const highlightColor = (state) => {
  return state.highlightColor;
};

export const fontSize = (state) => {
  return state.fontSize;
};
export const fontName = (state) => {
  return state.fontName;
};

export const renderFontName = (state) => {
  return state.renderFontName;
};

export const renderFileBaseName = (state) => {
  return state.renderFileBaseName
    ? state.renderFileBaseName.toLowerCase()
    : "no-name";
};
export const renderBaseDirectory = (state) => {
  return (
    (state.renderBaseDirectory
      ? state.renderBaseDirectory.toLowerCase()
      : "/mkblite/"
    ).replace(/\/$/, "") + "/"
  );
};

export const playInterval = (state) => {
  return state.playInterval;
};

export const renderTextUpperCase = (state) => {
  return state.renderTextUpperCase;
};

export const renderFontSize = (state) => {
  return state.renderFontSize;
};
export const renderLineHeight = (state) => {
  return state.renderLineHeight;
};

export const renderServerURL = (state) => {
  return state.renderServerURL.replace(/\/$/, "") + "/";
};

export const renderBatch = (state) => {
  return state.renderBatch;
};

export const isServerRunning = (state) => {
  return state.isServerRunning;
};

export const isRendererOK = (state) => {
  if (process.env.IS_ELECTRON) {
    return true;
  }
  return state.isServerRunning && state.renderBatch;
};

export const tab2Column = (state) => {
  return state.tab2Column;
};

export const enableSpellCheck = (state) => {
  return state.enableSpellCheck;
};

export const enableFilters = (state) => {
  return state.enableFilters;
};

export const breakLine = (state) => {
  return state.breakLine;
};

export const breakLineWordLimit = (state) => {
  return parseInt(state.breakLineWordLimit);
};

export const breakLineEllipsis = (state) => {
  return state.breakLineEllipsis ? state.breakLineEllipsis : "...";
};

export const slugBandOffset = (state) => {
  // { x: 0, y: 0}
  return state.slugBandOffset;
};
