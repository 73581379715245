var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"fill-height border-right",attrs:{"cols":"12","md":_vm.CanRender ? 7 : 12}},[_c('vue-editable-grid',{ref:"grid",staticClass:"mt-2 grid",style:(_vm.gridStyle),attrs:{"id":"grid","column-defs":_vm.columnDefs,"row-data":_vm.rows,"only-border":true,"enable-filters":_vm.$store.getters['app/config/enableFilters'],"breakLine":_vm.$store.getters['app/config/breakLine'],"breakLineWordLimit":_vm.$store.getters['app/config/breakLineWordLimit'],"breakLineEllipsis":_vm.$store.getters['app/config/breakLineEllipsis'],"tab2Column":_vm.$store.getters['app/config/tab2Column'],"row-data-key":"sn","itemHeight":_vm.fontSize * 2},on:{"cell-updated":_vm.cellUpdated,"row-selected":_vm.rowSelected,"context-menu":_vm.onContextMenu,"contextmenu":_vm.onContextMenu,"keyup":_vm.onKeyUp},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-text-field',{staticClass:"mt-2 mx-2 input-100px",staticStyle:{"text-align":"right"},attrs:{"dense":"","flat":"","single-line":true,"append-outer-icon":"mdi-content-save","suffix":".xlsx"},on:{"click:append-outer":() => {
            _vm.handleDownload({
              filename: _vm.renderFileBaseName,
              allColumns: false,
              withHeader: false,
            });
          }},model:{value:(_vm.renderFileBaseName),callback:function ($$v) {_vm.renderFileBaseName=$$v},expression:"renderFileBaseName"}})]},proxy:true},{key:"header-r",fn:function(){return [_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Enable Text Search'),expression:"'Enable Text Search'"}],staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$store.dispatch('app/config/toggleEnableFilters')}}},[(_vm.$store.getters['app/config/enableFilters'])?_c('v-icon',[_vm._v("mdi-text-box-search-outline")]):_c('v-icon',[_vm._v("mdi-text-search")])],1),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Remove starting digits from rows.'),expression:"'Remove starting digits from rows.'"}],staticClass:"mr-2",attrs:{"color":"red","small":"","icon":""},on:{"click":_vm.handleRemoveStartingDigits}},[_c('v-icon',[_vm._v("mdi-counter")])],1),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Total Rows'),expression:"'Total Rows'"}],staticClass:"mr-2 noselect"},[_c('v-icon',{staticClass:"mb-1",attrs:{"small":""}},[_vm._v("mdi-format-list-bulleted")]),_vm._v(" "+_vm._s(_vm.$store.getters["app/home/rowCount"])+" ")],1)]},proxy:true}])}),(_vm.$store.getters['app/config/enableSpellCheck'])?_c('spell-check'):_vm._e(),_c('ImportXL',{attrs:{"hasHeader":false,"open":_vm.showUploader,"focusable":"false"},on:{"sheetImported":_vm.sheetImported}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }