<template>
  <v-app-bar app color="deep-purple accent-4" class="noselect" dense dark>
    <v-menu left bottom :offsetY="true">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="item in menuItems"
          :key="item.icon"
          @click="item.action"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-toolbar-title>
      MKB Lite
      <span class="badge orange darken-3 white--text noselect">{{
        $store.getters["getAppVersion"]
      }}</span>
    </v-toolbar-title>

    <v-spacer></v-spacer>
    <v-btn v-tooltip="'Refresh'" icon @click.stop="handleRefresh">
      <v-icon>mdi-refresh</v-icon>
    </v-btn>
    <v-btn v-tooltip="'Export...'" icon @click.stop="handleDownloadClick">
      <v-icon>mdi-file-download</v-icon>
    </v-btn>

    <editor-settings />
  </v-app-bar>
</template>
<script>
import bus from "@/bus";
import { mapGetters } from "vuex";
import EditorSettings from "@/app/config/modules/EditorSettings";
import { getRandomString } from "@/helpers/utils";

export default {
  components: { EditorSettings },
  data() {
    return {
      showUploader: null,
      menuItems: [
        {
          icon: "mdi-database-import",
          text: "Import...",
          action: () => {
            if (
              confirm(
                "Are you sure to remove existing rows and import file from hard drive?\nClick cancel and export existing rows if you haven't already."
              ) != true
            ) {
              return;
            }
            this.showUploader = !this.showUploader;
            bus.$emit("importFile", {
              append: false,
            });
          },
        },
        {
          icon: "mdi-database-plus",
          text: "Append...",
          action: () => {
            if (
              confirm(
                "Are you sure to append file at the end of existing rows?"
              ) != true
            ) {
              return;
            }
            this.showUploader = !this.showUploader;
            bus.$emit("importFile", {
              append: true,
            });
          },
        },
        {
          icon: "mdi-download",
          text: "Export All...",
          action: () => {            
            bus.$emit("download", {
              filename: null,
              allColumns: true,
              withHeader: true,
            });
          },
        },
        {
          icon: "mdi-restart",
          text: "Reset",
          action: () => {
            this.handleClearClick();
          },
        },

        {
          icon: "mdi-heart",
          text: "About",
          action: () => {
            bus.$emit("show-about-snackbar");
          },
        },
        {
          icon: "mdi-logout",
          text: "Logout",
          action: () => {
            this.$router.replace({ name: "logout" });
          },
        },
      ],
      version: "",
    };
  },
  computed: {
    ...mapGetters("app/auth", {
      authData: "authData",
    }),
  },
  methods: {
    handleRefresh() {
      this.$router.replace({ name: "refresher" });
    },
    handleDownloadClick() {
      bus.$emit("download");
    },
    handleClearClick() {
      if (confirm("Are you sure to reset all rows? This can't undo.") != true) {
        return;
      }
      this.$store.dispatch(
        "app/config/setRenderFileBaseName",
        "mkb-part-0" // + getRandomString(4)
      );
      bus.$emit("reset");
    },
  },
  mounted() {},
};
</script>

<style>
.badge {
  font-size: 0.6rem;
  position: absolute;
  background: rgb(255, 102, 0);
  color: white;
  padding: 0px 3px;
  border-radius: 0.4rem;
  top: 110;
  font-family: "Mukta SemiBold";
}
</style>