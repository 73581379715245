import localforage from "localforage";
import axiosUntouched from "axios";

export const setCurrentRoute = ({ commit }, route) => {
  commit("SET_CURRENT_ROUTE", route);
};

export const setCurrLocale = ({ commit }, locale) => {
  locale = locale ? locale : "en";
  localforage.setItem("currLocale", locale);
  commit("SET_CURR_LOCALE", locale);
};

export const setThemeDark = ({ commit }, trueOrFalse) => {
  localforage.setItem("isThemeDark", trueOrFalse).then(() => {
    localforage.getItem("isThemeDark").then((val) => {
      commit("SET_THEME_DARK", val);
    });
  });
};

export const toggleTheme = ({ state, dispatch }) => {
  var currTheme = state.isThemeDark ? state.isThemeDark : false;
  dispatch("setThemeDark", !currTheme);
};

export const setAlert = (
  { commit },
  { type, message, icon = null, translate = false }
) => {
  return; //
  commit("SET_ALERT", {
    type: type,
    message: message,
    icon: icon,
    translate: translate,
  });
  setTimeout(() => {
    commit("SET_ALERT", {
      type: null,
      message: null,
      icon: null,
      translate: false,
    });
  }, 3000);
};

export const apiAlert = ({ dispatch }, status) => {
  if (!status.code) status.code = 200;
  if (!status.message) return;

  var type = "info";

  if (status.code >= 200 && status.code <= 299) {
    type = "success";
  } else if (status.code >= 400 && status.code <= 499) {
    type = "error";
  } else if (status.code >= 500 && status.code <= 599) {
    type = "error";
  } else {
    type = "info";
  }

  dispatch("setAlert", { type: type, message: status.message });
};

export const resetState = ({ commit }) => {
  commit("RESET_STATE");
};

export const setFormErrors = ({ commit }, { errors }) => {
  let errs = {};
  errors.forEach((err) => {
    errs[err.field] = err.message;
  });
  commit("SET_FORM_ERRORS", { errors: errs });
};

export const fetchSupportedVersion = ({ commit }) => {
  return (
    axiosUntouched
      .get("https://api.vikarn.com/excelite/version/supported")
      .then((res) => {
        const code = res.data && res.data.buildCode ? res.data.buildCode : -1;
        commit("SET_SUPPORTED_VERSION", code);
        return Promise.resolve(res.data.buildCode);
      })
      .catch((err) => {
        commit("SET_SUPPORTED_VERSION", -1);
        return Promise.reject(err);
      })
      .finally(() => {})
  );
};

export const appUpdatedViaServiceWorker = ({ commit }) => {
  //commit("RESET_STATE");
  console.log("..");
  setTimeout("location.reload(true);", 3000);
};

export const setLocked = ({ commit }, trueFalse) => {
  commit("SET_LOCKED", trueFalse);
};
